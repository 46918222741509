import { OrderStatus } from '@/modules/common/types';

export const HIDDEN_MAP_TOOLS_PANEL_STATUS = [
  // OrderStatus.AwaitingApproval,
  // OrderStatus.ResolvingFeedback,
  OrderStatus.Draft,
  OrderStatus.Archived,
  OrderStatus.MeasurementOngoing,
];

export const HIDDEN_MAP_TOOLS_PANEL_STATUS_FOR_ESTIMATOR = [
  OrderStatus.Draft,
  OrderStatus.Archived,
  OrderStatus.MeasurementOngoing,
];

export const LAYER_PANEL_ALLOWED_STATUS = [
  OrderStatus.AwaitingApproval,
  OrderStatus.ResolvingFeedback,
  OrderStatus.BoltApproved,
  OrderStatus.BoltPreview,
  OrderStatus.BoltInVerification,
  OrderStatus.BoltFailed,
];
