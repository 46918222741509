// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon64IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon64Icon(props: Icon64IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.277 15.854a.423.423 0 01-.293-.1.388.388 0 01-.117-.281l-.176-6.141a.344.344 0 01.106-.275.396.396 0 01.293-.112c.12 0 .219.035.293.106.074.07.111.162.111.275l.182 6.14a.37.37 0 01-.111.282.388.388 0 01-.288.106zm1.723 0a.43.43 0 01-.299-.106.362.362 0 01-.111-.275V9.332c0-.113.037-.205.111-.275A.416.416 0 0112 8.945c.125 0 .225.037.299.112.078.07.117.162.117.275v6.14a.353.353 0 01-.117.276.416.416 0 01-.299.106zm1.729 0a.416.416 0 01-.3-.106.362.362 0 01-.099-.281l.176-6.135a.388.388 0 01.117-.281.4.4 0 01.287-.106c.125 0 .223.037.293.112.074.07.11.162.106.275l-.176 6.14a.388.388 0 01-.117.282.414.414 0 01-.287.1zM9.338 7.31V6.045c0-.45.136-.799.405-1.049.274-.254.652-.38 1.137-.38h2.226c.485 0 .864.126 1.137.38.274.25.41.6.41 1.049V7.31H13.57V6.098a.462.462 0 00-.146-.358.543.543 0 00-.393-.14h-2.074a.535.535 0 00-.387.14.463.463 0 00-.146.358V7.31H9.34zm-2.595.644a.503.503 0 01-.369-.152.503.503 0 01-.152-.37c0-.14.05-.26.152-.357a.503.503 0 01.37-.152h10.517c.144 0 .265.049.363.146a.483.483 0 01.152.364c0 .144-.05.267-.152.369a.484.484 0 01-.363.152H6.744zm2.567 9.885c-.454 0-.817-.127-1.09-.381-.27-.254-.414-.607-.434-1.06l-.41-8.555h1.072l.405 8.373a.626.626 0 00.17.422c.105.109.24.164.404.164h5.144a.538.538 0 00.405-.164.611.611 0 00.17-.422l.386-8.373h1.09l-.404 8.549c-.02.453-.166.806-.44 1.06-.273.258-.634.387-1.084.387H9.311z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon64Icon;
/* prettier-ignore-end */
