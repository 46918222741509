import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
// import { Button } from 'antd';
import TryNewMapDropdown from '../../PlasmicComponents/TryNewMapDropdown';
import { ChevronDown, Cross, ArrowLeft, CrosshairIcon, X } from 'lucide-react';
import ChevronDown2Icon from '../../PlasmicComponents/plasmic/app_siterecon/icons/PlasmicIcon__ChevronDown2';
import { Card, Input, Button, Upload, Space, Spin } from 'antd';
import { LeftOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { FileImageOutlined, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import RequestReceivedModal from '../../pages/project/projectComponents/RequestReceivedModal';
const { TextArea } = Input;
import newIcon from './trynewmap.png';
import { getAxiosInstance } from '../../../helpers/utilities/api-utils';
import { NotifyError } from '../../../helpers/notification-utils';
import './betamap.css';
import { trackEvents } from '../../../helpers/utilities';
import { createPortal } from 'react-dom';

const axiosInstance = getAxiosInstance();

const CustomButton = ({ onClick, children, disabled = false, style = {} }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{ ...style }}
      className="custom-button"
    >
      {children}
    </button>
  );
};

/******* api stufff */

// Helper function to check file size (25MB limit)
const isFileSizeGreaterThan25MB = (size) => {
  const maxSize = 25 * 1024 * 1024; // 25MB in bytes
  return size > maxSize;
};

// Helper function to convert base64 to blob
const base64ToBlob = (base64Data, contentType) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

// 1. Function to handle file upload
// 1. Function to handle file upload
const handleFileUpload = async (files, setFiles, setFileUploading, setRandom) => {
  const file = files[files.length - 1];
  const { uid, name, type, size, lastModified, originFileObj } = file;
  // Check file type
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
  if (!allowedTypes.includes(type)) {
    throw new Error('Only PDF, jpg, png, jpeg file extensions are allowed in attachment');
  }

  // Check file size
  if (isFileSizeGreaterThan25MB(size)) {
    throw new Error('File size exceeds the 25 MB limit. Please upload a smaller file.');
  }

  // Create FormData
  const formData = new FormData();

  // Append file based on type
  if (['image/png', 'image/jpeg', 'image/jpg'].includes(type)) {
    formData.append('uploadImage', originFileObj, name);
  } else if (type === 'application/pdf') {
    formData.append('uploadFile', originFileObj, name);
  }

  try {
    const { data } = await axiosInstance.post('/api/user/uploadFiles/?thumbnail=true',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );

    const updatedFileList = files.map(file => {
      // Check if this is the file that just finished uploading
      if (file.uid === uid) {
        return {
          ...file,
          loading: false,  // Set loading to false for the uploaded file
          imageUrl: data.imgUrl  // Add the image URL from the response
        };
      }
      // Return other files unchanged
      return file;
    });

    setFiles(updatedFileList);

    // return data.imgUrl; // Return the uploaded file URL
  } catch (error) {
    // setFileUploading(false);
    throw error;
  }
};

// Slack message sending function
const sendSlackMessage = async (webhookUrl, heading, description, imageUrls = []) => {
  // Create the base message blocks
  const blocks = [
    {
      "type": "header",
      "text": {
        "type": "plain_text",
        "text": heading,
        "emoji": true
      }
    },
    {
      "type": "section",
      "text": {
        "type": "mrkdwn",
        "text": description
      }
    }
  ];

  // Add image blocks for each image
  imageUrls.forEach(imageUrl => {
    if (imageUrl) {
      blocks.push({
        "type": "image",
        "title": {
          "type": "plain_text",
          "text": "Feedback Attachment"
        },
        "image_url": imageUrl,
        "alt_text": "Feedback attachment image"
      });
    }
  });

  const payload = { blocks };

  const response = await fetch(webhookUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    throw new Error(`Slack API error: ${response.status}`);
  }

  return true;
};

/*** api stuff end */

const SwitchBackIcon = ({
  width = 24,
  height = 24,
  color = 'black',
  strokeWidth = 2,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M9 14L4 9L9 4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20V13C20 11.9391 19.5786 10.9217 18.8284 10.1716C18.0783 9.42143 17.0609 9 16 9H4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const sampleFiles = [
  {
    uid: '1',
    name: 'document.pdf',
    status: 'done',
    url: 'http://example.com/document.pdf',
    type: 'application/pdf',
    size: 2048
  },
  {
    uid: '2',
    name: 'screenshot.png',
    status: 'done',
    url: 'http://example.com/screenshot.png',
    type: 'image/png',
    size: 1024
  },
  {
    uid: '3',
    name: 'image.png',
    status: 'done',
    url: 'http://example.com/image.png',
    type: 'image/png',
    size: 3072
  }
];

// You can initialize your state with this:

const FeedbackForm = ({ onCancel = () => { }, handleFeedbackSuccess = () => { } }) => {
  const [files, setFiles] = useState([]);
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async () => {
    try {
      // Get all image URLs from files array
      const imageUrls = files.map(file => file.imageUrl).filter(Boolean);

      // Prepare the webhook URL
      const webhookUrl = 'https://hooks.slack.com/services/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';

      // Send to Slack
      await sendSlackMessage(
        webhookUrl,
        "Map Feedback Dev Testing",  // Heading
        feedback,        // Description/feedback text
        imageUrls       // Array of image URLs
      );

      // Clear form after successful submission
      setFeedback('');
      setFiles([]);

      // Optionally show success message
      handleFeedbackSuccess();
      // message.success('Feedback submitted successfully!');

      // Close the feedback form if needed
      onCancel();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      // message.error('Failed to submit feedback. Please try again.');
    }
  };

  const handleCancel = () => {
    // setFeedback('');
    // setFiles([]);
    onCancel();
  };

  const handleFileChange = ({ fileList }) => {
    // Map through new fileList and preserve loading states from existing files
    const updatedFileList = fileList.map(file => {
      // Check if this file exists in current files array
      const existingFile = files.find(f => f.uid === file.uid);
      return {
        ...file,
        // If file exists and has loading: false, keep it false. Otherwise set to true
        loading: existingFile ? existingFile.loading : true
      };
    });
    handleFileUpload(updatedFileList, setFiles);
    setFiles(updatedFileList);
  };


  const FileItem = ({ file }) => {
    const isPdf = file.name.toLowerCase().endsWith('.pdf');

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px',
        background: '#f5f5f5',
        borderRadius: '4px',
        // marginBottom: '6px',
        width: '100%'
      }}>
        {file.loading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 16,
                  color: 'rgb(255, 215, 0)'
                }}
                spin
              />
            }
          />
        ) : isPdf ? (
          <FilePdfOutlined style={{ fontSize: '20px', color: '#ff4d4f' }} />
        ) : (
          <FileImageOutlined style={{ fontSize: '20px', color: '#666' }} />
        )}
        <span style={{
          color: '#666',
          fontSize: '12px',
          flexGrow: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>{file.name}</span>
      </div>
    );
  };

  const buttonStyle = {
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: 'inherit',
      color: 'inherit'
    }
  };
  const isAnyFileLoading = files.some(file => file.loading);

  return (
    <Card
      style={{
        position: 'absolute',
        top: '100%',
        right: 0,
        marginTop: '8px',
        fontSize: '12px',
        width: 'auto',
        minWidth: 320,
        maxWidth: 360,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        backgroundColor: '#fff',
        zIndex: 1000,
      }}
      bodyStyle={{ padding: '12px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
        <h2 style={{
          margin: 0,
          fontSize: '12px',
          fontWeight: 500,
          color: 'rgba(0, 0, 0, 0.85)'
        }}>
          Help us improve the new maps! Let us know what you love or what needs improvement.
        </h2>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <TextArea
            placeholder="Describe your issue or suggestion..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            style={{
              minHeight: '100px',
              fontSize: '12px',
              resize: 'none',
              borderRadius: '4px'
            }}
          />
          <span style={{
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.45)'
          }}>
            Input required
          </span>
        </div>

        <div style={{ margin: '0 auto', marginTop: -10 }}>
          <Upload
            // multiple
            fileList={files}
            onChange={handleFileChange}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              icon={<UploadOutlined />}
              style={{
                height: 'auto',
                padding: '6px 16px',
                display: 'flex',
                alignItems: 'center',
                // gap: '8px',
                border: 0,
                fontSize: '14px',
                backgroundColor: '#fff',
                // borderColor: '#d9d9d9',
                color: 'rgba(0, 0, 0, 0.85)',
                ...buttonStyle
              }}
            >
              Add Attachments
            </Button>
          </Upload>
        </div>

        {/* File Grid */}
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '8px',
          marginTop: '0'
        }}>
          {files.map(file => (
            <FileItem key={file.uid} file={file} />
          ))}
        </div>

        <div style={{
          display: 'flex',
          gap: '12px',
          // marginTop: '8px'
        }}>
          <Button
            onClick={handleCancel}
            style={{
              flex: 1,
              height: 'auto',
              padding: '6px 16px',
              fontSize: '12px',
              backgroundColor: '#fff',
              borderColor: '#d9d9d9',
              color: 'rgba(0, 0, 0, 0.85)',
              ...buttonStyle
            }}
            className='custom-hover-add-feedback-button'
          >
            Cancel
          </Button>
          <Button
            onClick={isAnyFileLoading ? () => { } : handleSubmit}
            style={{
              flex: 1,
              height: 'auto',
              padding: '6px 16px',
              fontSize: '12px',
              backgroundColor: isAnyFileLoading ? '#f5f5f5' : '#FFD700',
              borderColor: isAnyFileLoading ? '#d9d9d9' : '#FFD700',
              color: isAnyFileLoading ? 'rgba(0, 0, 0, 0.25)' : '#000',
              cursor: isAnyFileLoading ? 'not-allowed' : 'pointer',
              ...buttonStyle
            }}
          >
            Submit Feedback
          </Button>
        </div>
      </div>
    </Card>
  );
};

// import { createPortal } from 'react-dom';

// Create a separate PopoverCard component
const PopoverCard = ({
  isOpen,
  isNewMap,
  handleMainButtonClick,
  handleSwitchToNewMap,
  handleAddFeedback,
  handleSwitchToLegacy,
  CustomButton
}) => {
  if (!isOpen) return null;

  return createPortal(
    <Card
      style={{
        position: 'fixed', // Changed from absolute to fixed for portal
        top: 102, // Adjust based on your layout
        right: 45, // Adjust based on your layout
        // fontSize: '10px',
        width: 330,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        backgroundColor: '#fff',
        zIndex: 2147483647 // Maximum z-index

        // position: 'absolute',
        // top: '100%',
        // right: 0,
        // marginTop: '8px',
        // fontSize: '10px',
        // // width: 'auto',
        // width: 330,
        // // minWidth: 320,
        // // maxWidth: 360,
        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        // backgroundColor: '#fff',
        // zIndex: 1000

      }}
      bodyStyle={{ padding: '12px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <X
          size={18}
          onClick={handleMainButtonClick}
          style={{
            marginLeft: '4px',
            color: '#666666',
            transition: 'all 0.3s ease',
            position: 'absolute',
            right: 8,
            top: 8,
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.color = '#333333';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.color = '#666666';
          }}
        />
        {isNewMap ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <p style={{
              fontSize: '12px',
              color: '#333333',
              margin: 0,
              lineHeight: 1.6,
              textAlign: 'left'
            }}>
              We've re-engineered our core mapping technology to make your workflow efficient.
            </p>

            <ol style={{
              listStyle: 'none',
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              padding: 0,
            }}>
              <li style={{
                display: 'flex',
                gap: '8px',
                fontSize: '12px',
                color: '#666'
              }}>
                <span>Now, experience:</span>
              </li>
              <li style={{
                display: 'flex',
                gap: '8px',
                fontSize: '12px',
                color: '#666'
              }}>
                <span>1.</span>
                <span>Lightning-fast map interactions</span>
              </li>
              <li style={{
                display: 'flex',
                gap: '8px',
                fontSize: '12px',
                color: '#666'
              }}>
                <span>2.</span>
                <span>Effortless handling of large properties</span>
              </li>
            </ol>

            <p style={{
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.65)',
              margin: 0,
              lineHeight: 1.6,
              textAlign: 'left'
            }}>
              And this is just the beginning—more powerful tools are en route!
            </p>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <p style={{
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.65)',
              margin: 0,
              lineHeight: 1.6,
              textAlign: 'left',
              fontFamily: 'Poppins'
            }}>
              Facing issues with the new map experience or need access to tools still in development (like Snapshot, Measure Tool)? You can switch back to Legacy Maps.
            </p>
            <p style={{
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.65)',
              margin: 0,
              lineHeight: 1.6,
              textAlign: 'left',
              fontFamily: 'Poppins'
            }}>
              We're still in beta and will truly appreciate your feedback. Please let us know if you encounter any issues, and we'll address them in the upcoming stable release!
            </p>
          </div>
        )}

        {isNewMap ? (
          <div style={{ display: 'flex' }}>
            <CustomButton style={{ flex: 1 }} onClick={handleSwitchToNewMap}>
              Switch to New Maps
            </CustomButton>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              size="middle"
              onClick={handleAddFeedback}
              style={{
                fontSize: '12px',
                height: 'auto',
                padding: '6px 16px',
                border: '1px solid #d9d9d9',
                backgroundColor: '#ffffff',
                color: '#666666',
              }}
              className='custom-hover-add-feedback-button'
            >
              Add Feedback
            </Button>

            <CustomButton onClick={handleSwitchToLegacy}>
              Switch To Legacy Maps
            </CustomButton>
          </div>
        )}
      </div>
    </Card>,
    document.getElementById('draggable-portal-root')!
  );
};

// Main component
const LegacyMapsSwitch = ({ currentUserEmail = '', isNewMap = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [isRequestReceivedPopupVisible, setIsRequestReceivedPopupVisible] = useState(false);

  const handleSwitchToLegacy = () => {
    console.log('Switching to legacy maps...');
    const currentPath = location.pathname;
    const cleanPath = currentPath.replace(/^\/beta/, '');
    const betaUrl = `${window.location.origin}${cleanPath}`;
    trackEvents('beta-map__switch-to-legacy-map');
    window.open(betaUrl, '_blank');
  };

  const handleSwitchToNewMap = () => {
    console.log('Switching to legacy maps...');
    const currentPath = location.pathname;
    const cleanPath = currentPath.replace(/^\/beta/, '');
    const betaUrl = `${window.location.origin}/beta${cleanPath}`;
    trackEvents('beta-map__switch-to-new-map');
    window.open(betaUrl, '_blank');
  };

  const handleAddFeedback = () => {
    setIsOpen(false);
    setIsFileUploadOpen(true);
    trackEvents('beta-map__open-feedback-modal');
  };

  const handleMainButtonClick = () => {
    if (isNewMap) {
      // Track try new map event with state
      trackEvents(`beta-map__click-try-new-map-${isOpen ? 'closed' : 'open'}`);
      if (!isFileUploadOpen) {
        setIsOpen(!isOpen);
      }
    } else {
      trackEvents(`beta-map__click-go-to-legacy-map-${isOpen ? 'closed' : 'open'}`);
      if (isFileUploadOpen) {
        setIsFileUploadOpen(false);
      }
      setIsOpen(!isOpen);
    }
  }

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
      marginTop: 6,
    }}>
      <Button
        onClick={handleMainButtonClick}
        size="large"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="custom-hover-button"  // Add this className
        style={{
          height: 'auto',
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '12px',
          fontWeight: 500,
          backgroundColor: isHovered ? '#F5F5F5' : '#FFFFFF',
          border: '1px solid #DDDDDD',
          color: '#333333', // Keep text color constant
          transition: 'all 0.3s ease'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {isNewMap ? <>
            <img
              src={newIcon}
              alt="Switch back"
              width={22}
              height={22}
              style={{ objectFit: 'contain' }}
            />
            <span>Try New Map Experience</span>
          </> : <>
            <SwitchBackIcon strokeWidth={2} width={18} height={18} />
            <span>Switch Back to Legacy Maps</span>
          </>
          }
        </div>
        <ChevronDown
          size={18}
          style={{
            marginLeft: '4px',
            color: 'rgba(0, 0, 0, 0.85)',
            transition: 'all 0.3s ease'
          }}
        />
      </Button>

      {isRequestReceivedPopupVisible &&
        <RequestReceivedModal
          visible={isRequestReceivedPopupVisible}
          setVisible={setIsRequestReceivedPopupVisible}
        />}

      {isFileUploadOpen && <FeedbackForm
        onCancel={() => {
          setIsOpen(false)
          setIsFileUploadOpen(false)
        }}
        handleFeedbackSuccess={() => {
          setIsRequestReceivedPopupVisible(true);
        }}
      />}
      <PopoverCard
        isOpen={isOpen}
        isNewMap={isNewMap}
        handleMainButtonClick={handleMainButtonClick}
        handleSwitchToNewMap={handleSwitchToNewMap}
        handleAddFeedback={handleAddFeedback}
        handleSwitchToLegacy={handleSwitchToLegacy}
        CustomButton={CustomButton}
      />
    </div>
  );
};


export default function BetaMapButton({ currentUserEmail = '', isNewMap = false }) {
  const location = useLocation();

  const handleBetaSwitch = () => {
    const currentPath = location.pathname;
    const cleanPath = currentPath.replace(/^\/beta/, '');
    const betaUrl = `${window.location.origin}/beta${cleanPath}`;

    window.open(betaUrl, '_blank');
  };
  return <div style={{ position: 'relative', border: '0px solid red', zIndex: 1000000 }}>
    {/* <div style={{ position: 'absolute', top: 0 }}> */}
    <LegacyMapsSwitch currentUserEmail={currentUserEmail} isNewMap={isNewMap} />
    {/* <TryNewMapDropdown onSwitchMap={handleBetaSwitch} isNewMap={true} /> */}
    {/* </div> */}
  </div>


  // return (
  //   <Button
  //     key='back'
  //     btnType='secondary'
  //     style={{ height: '0px', width: '248px', zIndex: 1000, padding: 0, top: '-18px', border: 0 }}
  //   >
  //   </Button>
  // );
}


