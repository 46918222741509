import React, { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ReactComponent as LinearGradients } from './assets/action-center/linear-gradient.svg';
import * as ROUTES from './helpers/constants/routes';
import { getCurrentAnnouncementThunk } from './store/announcements/thunks';
import { getUserDetailThunk, workspaceSwitchThunk } from './store/user/thunks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { JotaiProvider } from './jotai';

// * PRIVATE ROUTE COMPONENT
import PrivateRoute from '@elements/private-route/PrivateRoute';

// * SUSPENSE FALLBACK COMPONENT ( LOADING SCREEN )
import { UserRole, UserRoleId } from '@/modules/common/types';
import useAuth from '@/modules/auth/hooks';
import Fallback from './components/elements/fallback/Fallback';
import OpenMobileApp from './components/elements/open-mobile-app';
import AddPeople from './components/pages/myProperties/components/properties/components/modals/addPeople';
import AddTag from './components/pages/myProperties/components/properties/components/modals/addTag';
import AccessWall from '@project/components/AccessWall';
import ShareOrderMap from './components/pages/share-map/shareOrderMap';

import {
  authorizationHeader,
  switchWorkspaceIfLocallyAvailable,
} from './helpers/auth';
import {
  IS_USER_PILOT_ENABLED,
  REACT_APP_SHOW_MOBILE_CARD,
} from './helpers/constants';
import { CONFIG_API } from './helpers/constants/APIEndpoints';
import ShortCutKeys from './helpers/utilities/shortCutKeys';
import useDeviceDetect from './hooks/useDeviceDetect';
import ShortCutsContext from './ShortCutsContext';
import getConfigurations from './store/configurations/thunk';
import {
  getOrgListThunk,
  getUserListThunk,
} from './store/teamManagement/thunk';
import { setSelectedOrg } from './helpers/notification-utils';
import { setTrackDetails } from './helpers/utilities/api-utils';
import { getSubscription, getSubscriptionData } from './store/subscription/thunk';
import { config } from '@/modules/common/config';
import {
  getRoleByUsersData,
  segmentGroupEvent,
  UserPilotInit,
  UserPilotReload,
} from '@/helpers/utilities';
import getBetaFeatures from '@/store/betaRelease/thunk';
import Alert from '@common/components/Alert';
import { useSubscription } from '@/modules/subscription/hooks';
import useOrganization from '@/modules/auth/hooks/organization';
import { segmentPageEvent } from './helpers/utilities';
import useRouterPlus from '@/modules/router-plus/hooks/use-router-plus';
import useRouterPlusRedirect from '@/modules/router-plus/hooks/use-router-plus-redirect';
import NotificationContainer from './components/storybook/NotificationToast/NotificationToast';
import GlobalSubscriptionComponents from "./components/subscription/components/GlobalSubscriptionComponents";
import { getWorkspaces, getWorkspaceSummary } from "@/store/workspace/thunk";
import { getRedirectionUrl, getSearchParamFromUrl, lazyRetry } from './helpers/utils';
import { AUTH_PAGE, PATH_WITH_NO_WORKSPACE_ID, SIGNUP_PAGE } from './modules/common/constants/urls';
import AuthComponentSwitch from "./components/pages/auth/AuthComponentSwitch";
import { ElementPositioningProvider } from './Contexts/ElementPositioningContext';
import { WidgetBarTabProvider } from './modules/property/hooks/useWidgetBar';
import {queryClient} from './modules/common/libs/query-client';

import { isThisDeviceIpad } from './events/constants';


// * ACCESS TO ALL PAGES
const Auth = lazy(() =>
  lazyRetry(() => import('./components/pages/auth/Auth'))
);
// const ShareMap = lazy(() => import('./components/pages/share-map/ShareMap'));
const PageNotFound = lazy(() =>
  lazyRetry(() => import('./components/pages/404/404'))
);
const TermsPage = lazy(() =>
  lazyRetry(() => import('./components/pages/agreement/Terms/Terms'))
);
const Logout = lazy(() => lazyRetry(() => import('./components/pages/logout')));
const PrivacyAndDataLicense = lazy(() =>
  lazyRetry(() =>
    import(
      './components/pages/agreement/PrivacyAndDataLicense/PrivacyAndDataLicense'
    )
  )
);
const Signup = lazy(() =>
  lazyRetry(() => import('./components/pages/signup/Signup'))
);
const Workspace = lazy(() =>
  lazyRetry(() => import('./components/pages/workspace/Workspace'))
);

// * RESTRICTED ACCESS PAGES
const Home = lazy(() =>
  lazyRetry(() => import('./components/pages/home/Home'))
);

const CreateOrder = lazy(() =>
  lazyRetry(() => import('./components/pages/createOrder/CreateOrder'))
);
const BulkProjectView = lazy(() =>
  lazyRetry(() => import('./components/pages/bulkProject/index.jsx'))
);
const MyProperties = lazy(() =>
  lazyRetry(() => import('./components/pages/myProperties/index'))
);
const Dashboards = lazy(() =>
  lazyRetry(() => import('./components/pages/dashboards/index'))
);
const SiteInspection = lazy(() =>
  lazyRetry(() => import('./components/pages/dashboards/site-inspection/index'))
);
const ProfileSettings = lazy(() =>
  lazyRetry(() => import('./components/pages/profile/ProfileSettings'))
);
const ProjectDetail = lazy(() =>
  lazyRetry(() => import('./components/pages/project/ProjectDetail'))
);

// const Feed = lazy(() => lazyRetry(() => import('./components/pages/feed')));
const Bolt = lazy(() => lazyRetry(() => import('./components/pages/bolt')));
const SCMVersion = lazy(() =>
  lazyRetry(() => import('./components/pages/scm-version'))
);
const InviteTeamMembers = lazy(() =>
  lazyRetry(() => import('./components/pages/invite-team-members'))
);

function App() {
  const dispatch = useDispatch();
  const {
    organizationId,
    email,
    logout,
    authenticated,
    user,
    token,
    role,
    loading,
  } = useAuth();
  const { deviceType, width, handleClick } = useDeviceDetect();
  const { currentPlan, subscription: currentSubscriptionData } = useSubscription();
  const organization = useOrganization();
  const organizationData = useSelector(
    (state) => state.teamManagement.organisationData
  );

  const location = useLocation();

  useRouterPlus(
    'workspaceId',
    {
      mode: 'search',
      onChange: (newWorkspaceId) => {
        if ((!organizationId && !newWorkspaceId) || !newWorkspaceId) return;

        if (!organizationId && Boolean(newWorkspaceId)) {
          /** If organization ID is not available means user is directly opening some page with workspaceId  */
          if (!localStorage.getItem('sr_token')) {
            return;
          }
          const data = JSON.parse(localStorage.getItem('sr_token') || '');
          // check if current use is sr-estimator in this case change wkp token
          let currUser;
          try {
            const storedSrInfo = localStorage.getItem('sr_info');
            currUser = storedSrInfo ? JSON.parse(storedSrInfo) : null;
            if (currUser.user && currUser.user.user_role_id === 6) {
              dispatch(
                workspaceSwitchThunk({
                  workspaceData: { workspaceId: newWorkspaceId, ignoreReload: true,  },
                })
              );
              return;
            }
          } catch (error) {
            console.error('Error parsing sr_info from localStorage:', error);
            currUser = null;
          }

          if (!data.user?.organization_id) return;

          if (newWorkspaceId === String(data.user.organization_id)) return;
        }

        if (String(organizationId) === newWorkspaceId) {
          /** If the organization currently selected have same id as new organization ID from URL, we don't want to do anything. */
          return;
        }

        if (!switchWorkspaceIfLocallyAvailable(newWorkspaceId)) {
          if (window.location.href.includes('/project')) {
            /** When user is on `/project` page and the url `worksapceId` id not available in the user's workspaces, we want to show unauthorized worksapce rather than switching it. So don't need to call `switchWorkspace` */
            return;
          }
          dispatch(
            workspaceSwitchThunk({
              workspaceData: { workspaceId: newWorkspaceId },
              mode: 'reload',
            })
          );
        }
      },
    },
    []
  );

  const { redirect } = useRouterPlusRedirect();

  const currLocation = window.location.pathname;

  const isAuthPage = ['/auth', '/signup'].includes(currLocation)

  useEffect(() => {
    if (isAuthPage) {
      const script = document.createElement('script');
      script.src =
        'https://secure.enterprise-operation-inspired.com/js/786002.js';
      script.async = true;
      document.body.appendChild(script);

      /** Remove the script when the component unmounts */
      return () => document.body.removeChild(script);
    }
  }, [currLocation]);

  const [userRoleId, setUserRoleId] = React.useState(null);
  const [orgIdLocal, setOrgIdLocal] = React.useState(null);

  useEffect(() => {
    if (organizationData.length && userRoleId) {
      dispatch(getWorkspaces());
      dispatch(getWorkspaceSummary());
      dispatch(
        getConfigurations({
          url: CONFIG_API,
          header: authorizationHeader(),
        })
      );
      dispatch(getBetaFeatures());
      dispatch(getCurrentAnnouncementThunk());
      dispatch(getUserListThunk({ organisationId: orgIdLocal }));
      if (config.isSubscriptionsEnabled) {
        dispatch(getSubscriptionData(email));
        if (userRoleId !== UserRoleId.Estimator) dispatch(getSubscription())
      }
    }
  }, [organizationData, userRoleId, config.isSubscriptionsEnabled, orgIdLocal]);

  useEffect(() => {
    if (authenticated) {
      dispatch(getUserDetailThunk())
        .then(({ organization_id, email, user_role_id }) => {
          setUserRoleId(user_role_id);
          setOrgIdLocal(organization_id);
          dispatch(getOrgListThunk(email));
          
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(getWorkspaceSummary());
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(
          //   getConfigurations({
          //     url: CONFIG_API,
          //     header: authorizationHeader(),
          //   })
          // );
          // if (organizationData?.length) dispatch(getBetaFeatures());
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(getCurrentAnnouncementThunk());
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(getUserListThunk({ organisationId: organization_id }));
          /** If the Subscriptions are disabled, we don't want to hit the Subscription Details API Endpoint */
          // if (config.isSubscriptionsEnabled) {
            // dispatch(getSubscriptionData(email));
            // if (user_role_id !== UserRoleId.Estimator) dispatch(getSubscription())
          // }
        })
        .catch(() => {
          logout();
        });
    }
  }, [authenticated]);

  useEffect(() => {
    // to prevent user from getting popup blocker
    if (window.location?.pathname.split('/')[1] === 'share_id') {
      return;
    }
    if (localStorage.getItem('sr_token')) {
      dispatch(getUserDetailThunk())
        .then(({ organization_id, email, user_role_id }) => {
          setUserRoleId(user_role_id);
          setOrgIdLocal(organization_id);
          dispatch(getOrgListThunk(email));
          
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(getWorkspaceSummary());
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(
          //   getConfigurations({
          //     url: CONFIG_API,
          //     header: authorizationHeader(),
          //   })
          // );
          // if (organizationData?.length) dispatch(getBetaFeatures());
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(getCurrentAnnouncementThunk());
          // if (organizationData?.length && user_role_id !== UserRoleId.Estimator) dispatch(getUserListThunk({ organisationId: organization_id }));
          /** If the Subscriptions are disabled, we don't want to hit the Subscription Details API Endpoint */
          // if (config.isSubscriptionsEnabled) {
            // dispatch(getSubscriptionData(email));
            // if (user_role_id !== UserRoleId.Estimator) dispatch(getSubscription())
          // }
        })
        .catch(() => {
          logout();
        });
    }
  }, []);

  const orgData = useSelector((state) => state.teamManagement.organisationData);
  const orgUsersData = useSelector(
    (state) => state.teamManagement.organisationUserData
  );
  const invitedUserData = useSelector(
    (state) => state.teamManagement.invitedUserData
  );

  useEffect(() => {
    if (orgData && orgData.length > 0) {
      const selectedOrg = orgData?.find((org) => org.id === organizationId);
      setSelectedOrg({ email, ...selectedOrg });
      setTrackDetails({ email, ...selectedOrg });
    }
  }, [orgData, organizationId, email]);

  /** Identify event for UserPilot */
  useEffect(() => {
    if (
      currentPlan &&
      role &&
      email &&
      user &&
      organization &&
      Boolean(orgData.length) &&
      IS_USER_PILOT_ENABLED
    ) {
      UserPilotInit(email, {
        userId: user.id,
        userName: user.first_name,
        email: email,
        role: role,
        credits: user.totalcredits,
        currentPlan: currentPlan,
        signupDate: user.date_joined,
        workspaceId: orgData.map(({ id }) => id).map(String),
        workspaceName: orgData.map(({ title }) => title).map(String),
        primaryOnboardingComplete: 'NA',
        demoSessionBooked: 'NA',
        mobileAppActive: 'NA',
        powerUser: 'NA',
        enterpriseType: 'NA',
        pendingDiscoveryMeeting: 'NA',
        pendingDiscoveryFeatures: 'NA',
        customProperty1: 'NA',
        customProperty2: 'NA',
        customProperty3: 'NA',
        customProperty4: 'NA',
        customProperty5: 'NA',
        company: {
          id: organization.id.toString(),
          name: organization.title,
          workspaceUsers: organization.all_users,
          noOfWorkspaceUsers: organization.all_users.length,
        },
      });
    }
  }, [currentPlan, role, email, user?.id, organization?.id, orgData]);

  const refreshOnWorkspaceChange = () => {
    if (!token) {
      window.removeEventListener('focus', null);
      return;
    }

    /** If the user token stored in redux is not equals to token in local storage, it means workspace is changed. So we need to reload the workspace for current tab */
    if (token !== JSON.parse(localStorage.getItem('sr_token'))?.token) {
      window.location.href = '/';
    }
  };

  /** Code to refresh the page if workspace is changed in some another tab */
  useEffect(() => {
    if (authenticated) {
      window.addEventListener('focus', refreshOnWorkspaceChange);
    } else {
      window.removeEventListener('focus', refreshOnWorkspaceChange);
    }

    return () => {
      window.removeEventListener('focus', refreshOnWorkspaceChange);
    };
  }, [authenticated]);

  useEffect(() => {
    if (user?.email) {
      window.analytics.identify(user?.email, {
        userName: user?.first_name,
      });
    }
  }, []);

  useEffect(() => {
    if (orgData && orgData.length > 0) {
      const selectedOrg = orgData?.find((org) => org.id === organizationId);
      const { admins, creator } = getRoleByUsersData(orgUsersData);
      if (selectedOrg?.id && user && currentSubscriptionData) {
        const payload = {
          id: selectedOrg.id.toString(),
          name: selectedOrg?.title,
          avatar: selectedOrg?.avatar,
          createdAt: selectedOrg?.created_at,
          totalUsers: orgUsersData?.filter((user) => !user.is_user_deactivated)
            .length,
          noOfCreators: creator?.length,
          noOfAdmins: admins?.length,
          noOfPending: invitedUserData?.length,
        };
        segmentGroupEvent(selectedOrg.id.toString(), payload);
      };
    }
  }, [
    orgData,
    organizationId,
    JSON.stringify(orgUsersData),
    JSON.stringify(invitedUserData),
  ]);

  useEffect(() => {
    if (orgData && orgData.length > 0) {
      const selectedOrg = orgData?.find((org) => org.id === organizationId);
      if (selectedOrg?.id) {
        segmentPageEvent({
          workspaceId: selectedOrg.id.toString(),
        });
      }
    }
  }, [orgData, currLocation]);

  useEffect(() => UserPilotReload(), [currLocation]);

  const background = location.state && location.state.background;

  useEffect(() => {
    const remove = () =>
      redirect(
        {},
        {
          keepQueryParams: true,
          removeQueryParams: 'workspaceId',
          mode: 'search',
        }
      );
    const hasWorkspaceId = window.location.search.includes('workspaceId=');

    /** If user is on "/workspace" page, we don't want to add ?workspaceId=ID query param in URL */
    if (isAuthPage) {
      if (hasWorkspaceId) remove();
      return;
    }

    if (!organizationId) {
      return;
    }

    /** If URL already have a workspaceId query parameter, we don't want to continue. */
    if (hasWorkspaceId) return;
    redirect(
      { workspaceId: String(organizationId) },
      { keepQueryParams: true, mode: 'search' }
    );
  }, [organizationId, location.pathname, location.search]);

  useEffect(() => {
    if (!authenticated) return;

    if (!PATH_WITH_NO_WORKSPACE_ID.includes(location.pathname) && location.search.includes('redirect=')) {
      try {
        /** When user is not on auth pages, and we have a `redirect` query param in URL we need to redirect user to that page */
        window.location.href = decodeURI(getRedirectionUrl(window.location.search));
      } catch { }
    }
  }, [authenticated, location.pathname, location.search]);

  if (loading) {
    return <Fallback isMobileDevice={deviceType === 'Mobile' && width < 768} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <>
          <Suspense
            fallback={
              <Fallback isMobileDevice={deviceType === 'Mobile' && width < 768} />
            }
          >
            <Switch location={background || location}>
            <PrivateRoute
              exact
              path='/workspace'
              except={UserRole.Estimator}
              component={Workspace}
              showNavbar={false}
            />
            <PrivateRoute 
              exact 
              path='/' 
              except={UserRole.Estimator} 
              component={MyProperties} 
            />
            <PrivateRoute
              exact
              path='/reports'
              except={UserRole.Estimator}
              component={Dashboards}
              showNavbar={true}
            />
            <PrivateRoute
              exact
              path='/reports/site-inspection'
              except={UserRole.Estimator}
              component={SiteInspection}
              showNavbar={true}
            />
            <PrivateRoute
              exact
              path='/createOrder'
              except={[UserRole.Guest, UserRole.Estimator]}
              render={(props) => (
                <ShortCutKeys>
                  <ShortCutsContext.Consumer>
                    {(shortCutRef) => (
                      <ElementPositioningProvider>
                        <CreateOrder
                          shortCutRef={shortCutRef}
                          {...props}
                        />
                      </ElementPositioningProvider>
                    )}
                  </ShortCutsContext.Consumer>
                </ShortCutKeys>
              )}
            />
            <PrivateRoute
              exact
              path='/createOrderOld'
              except={[UserRole.Guest, UserRole.Estimator]}
              render={(props) => (
                <ShortCutKeys>
                  <ShortCutsContext.Consumer>
                    {(shortCutRef) => (
                      <ElementPositioningProvider>
                        <Home
                          shortCutRef={shortCutRef}
                          {...props}
                        />
                      </ElementPositioningProvider>
                    )}
                  </ShortCutsContext.Consumer>
                </ShortCutKeys>
              )}
            />
            <PrivateRoute 
              path='/settings' 
              except={UserRole.Estimator}
              component={ProfileSettings}
            />
            <PrivateRoute
              exact
              path='/project/:hash/:viewId?'
              render={(props) => (
                <ShortCutKeys>
                  <ShortCutsContext.Consumer>
                    {(shortCutRef) => (
                        <ElementPositioningProvider>
                      <WidgetBarTabProvider initialTab={null} disabledTabs={[]}>
                          <ProjectDetail
                            key={props.location.key}
                            {...props}
                            shortCutRef={shortCutRef}
                          />
                      </WidgetBarTabProvider>
                        </ElementPositioningProvider>
                    )}
                  </ShortCutsContext.Consumer>
                </ShortCutKeys>
              )}
            />
            <PrivateRoute
              exact
              path='/properties/:bulkOrderId/:viewId?'
              except={UserRole.Estimator}
              render={(props) => (
                <ShortCutKeys>
                  <ShortCutsContext.Consumer>
                    {(shortCutRef) => (
                      <ElementPositioningProvider>
                        <WidgetBarTabProvider initialTab={null}>
                          <BulkProjectView
                            key={props.location.key}
                            {...props}
                            shortCutRef={shortCutRef}
                          />
                        </WidgetBarTabProvider>
                      </ElementPositioningProvider>
                    )}
                  </ShortCutsContext.Consumer>
                </ShortCutKeys>
              )}
            />
            {/* <PrivateRoute exact path='/feed' component={Feed} /> */}
            <PrivateRoute
              exact
              path='/bolt'
              except={UserRole.Estimator}
              component={Bolt}
              showNavbar={false}
              except={UserRole.Guest}
            />
            <PrivateRoute
              exact
              path='/bolt/:hash'
              except={UserRole.Estimator}
              component={Bolt}
              showNavbar={false}
              except={UserRole.Guest}
            />
            <Route
              exact
              path={AUTH_PAGE}
              except={UserRole.Estimator}
              component={AuthComponentSwitch}
            />
            <Route 
              exact 
              path={SIGNUP_PAGE} 
              except={UserRole.Estimator}
              component={Signup} 
            />
            <Route
              path='/signup/:privacyDataLicense'
              except={UserRole.Estimator}
              component={PrivacyAndDataLicense}
            />
            <Route
              exact
              path='/share_id/:hash/:viewId?'
              except={UserRole.Estimator}
              render={(props) => (
                <ShortCutKeys>
                  <ShortCutsContext.Consumer>
                    {(shortCutRef) => (
                      <ElementPositioningProvider>
                        <WidgetBarTabProvider initialTab={null}>
                          <ShareOrderMap
                            {...props}
                            key={props.location.key}
                            shortCutRef={shortCutRef}
                          />
                        </WidgetBarTabProvider>
                      </ElementPositioningProvider>
                    )}
                  </ShortCutsContext.Consumer>
                </ShortCutKeys>
              )}
            />
            <Route 
              exact 
              path='/scm-version' 
              component={SCMVersion} 
            />
            <Route
              exact
              path={ROUTES.INVITE_TEAM_MEMBERS}
              except={UserRole.Estimator}
              component={InviteTeamMembers}
            />
            <Route
              exact
              path='/forgotpassword/:token'
              except={UserRole.Estimator}
              render={(props) => <Auth {...props} />}
            />
            <Route
              exact
              path='/forgot-password'
              except={UserRole.Estimator}
              render={(props) => <Auth {...props} />}
            />
            <PrivateRoute path='/logout' component={Logout} />
            <Route path='/terms' component={TermsPage} />
            <Route path='/unauthorised' component={AccessWall} />
            <Route path='/404' component={PageNotFound} />
            <Redirect
              to={{
                pathname: '/404',
                location: currLocation,
              }}
            />
          </Switch>
            {/*
             * Why are we adding Linear Gradients separately?
             * SVGs can contain re-usable components inside that wrapped under <defs>
             * These reusable components should be present once in one dom tree
             * Hence if we plan to put it along with the icon and the icon is reused at >1 places
             * The svg icon wouldn't know where to resolve this <def> from.
             * Hence putting it at once central place helps!
             * TODO: Maybe there is a better place to keep it?
             */}
            <LinearGradients />
          </Suspense>
          {background && (
            <Route path='/properties/modal/add-people' children={<AddPeople />} />
          )}
          {background && (
            <Route path='/properties/modal/add-tag' children={<AddTag />} />
          )}
          {!currLocation.includes('/invitation/') &&
            deviceType === 'Mobile' && !isThisDeviceIpad &&
            width < 768 && (
              <OpenMobileApp
                handleClick={handleClick}
                showMobileCard={REACT_APP_SHOW_MOBILE_CARD}
              />
            )}

          <GlobalSubscriptionComponents />
          <Alert />
          <NotificationContainer />
          {isAuthPage &&
            <noscript>
              <img
                alt=''
                src='https://secure.enterprise-operation-inspired.com/786002.png'
                style={{ display: 'none' }}
              />
            </noscript>}
        </>
        <div id="draggable-portal-root"></div>
        <div id="draggable-portal-root2"></div>
      </JotaiProvider>
    </QueryClientProvider>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
