// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Group427321656IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Group427321656Icon(props: Group427321656IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 18 19"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={"M10 17.667c-4.418 0-8-3.508-8-7.834S5.582 2 10 2"}
        stroke={"currentColor"}
        strokeWidth={"2.188"}
        strokeLinecap={"round"}
      ></path>
    </svg>
  );
}

export default Group427321656Icon;
/* prettier-ignore-end */
