// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon19IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon19Icon(props: Icon19IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20 20"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.005 12.282c-.234 0-.45-.096-.645-.288L5.747 8.298a.855.855 0 01-.166-.244.794.794 0 01-.054-.293.77.77 0 01.386-.679.734.734 0 01.39-.107c.219 0 .41.083.577.249l3.325 3.422H9.81l3.315-3.422a.786.786 0 01.571-.25c.143 0 .274.036.39.108a.75.75 0 01.28.283.744.744 0 01.107.396.73.73 0 01-.22.537l-3.608 3.696a.891.891 0 01-.64.288z"
        }
        fill={"#666"}
      ></path>
    </svg>
  );
}

export default Icon19Icon;
/* prettier-ignore-end */
