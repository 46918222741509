// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon65IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon65Icon(props: Icon65IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 25"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6.365 23.36c-1.08 0-1.904-.28-2.47-.84-.56-.554-.84-1.368-.84-2.442v-9.512c0-1.074.28-1.888.84-2.441.566-.56 1.39-.84 2.47-.84H9.12v2.178H6.541c-.43 0-.758.11-.986.332-.222.215-.332.547-.332.996v9.063c0 .449.11.784.332 1.005.228.222.556.332.986.332h10.908c.423 0 .749-.11.977-.332.228-.221.342-.556.342-1.006v-9.062c0-.45-.114-.781-.342-.996-.228-.221-.554-.332-.977-.332h-2.568V7.285h2.754c1.08 0 1.9.28 2.46.84.567.56.85 1.374.85 2.441v9.512c0 1.068-.283 1.882-.85 2.442-.56.56-1.38.84-2.46.84H6.365zm5.625-7.881a.959.959 0 01-.703-.293.948.948 0 01-.293-.694V4.785l.088-1.435-.566.732-1.29 1.377a.869.869 0 01-.664.283.91.91 0 01-.624-.234.818.818 0 01-.254-.615c0-.228.09-.437.273-.625l3.262-3.135a1.18 1.18 0 01.39-.264.997.997 0 01.771 0c.131.046.261.134.391.264l3.262 3.135c.182.188.274.397.274.625a.803.803 0 01-.264.615.897.897 0 01-.615.234.858.858 0 01-.655-.283l-1.298-1.377-.557-.732.078 1.435v9.707a.948.948 0 01-.293.693.95.95 0 01-.713.293z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon65Icon;
/* prettier-ignore-end */
